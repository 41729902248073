<template>
  <div id="footer" >
    
  </div>

</template>
 
<script>

export default {
  name: 'Footer',
  components: {
    
  },
  data() {
    return {
      
    };
  },

  methods : {
    
  }
};
</script>